<template>
  <div class="container xl">
    <div class="main-cols">
      <div class="left-menu-col">
        <LeftMenu />
      </div>
      <div class="right-content-col">
        <div class="failure-message">
          <h2 class="title">
            <span>Sorry</span>
          </h2>
          <p>
            Your payment failed, please try again or contact us at <a href="mailto:support@sendit.world">support@sendit.world</a>
          </p>
        </div>

        <div class="additional-links">
          <router-link to="/orders">View your orders</router-link>
          <a href="#" class="quote-n-book-trigger">Send another package</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu';

export default {
  name: 'OrdersFailureView',
  components: {
    LeftMenu,
  },
  mounted() {
    if (document.querySelector('.quote-n-book-trigger')) {
      document.querySelector('.quote-n-book-trigger').addEventListener('click', (e) => {
        e.preventDefault();
        let quoteNBookWidget = document.querySelector('.quote-n-book-widget');
        if (!quoteNBookWidget.classList.contains('expand')) {
          quoteNBookWidget.classList.add('expand');
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/partials/order-result-view.scss';
</style>
